export default {
  HYPERLOCAL_ASSETS: 'HYPERLOCAL_ASSETS',
  UPDATE_PREBOOKING_CONFIGURE: 'UPDATE_PREBOOKING_CONFIGURE',
  UPDATE_PREBOOKING_SUMMARY: 'UPDATE_PREBOOKING_SUMMARY',
  UPDATE_PREBOOKING_PAYMENT: 'UPDATE_PREBOOKING_PAYMENT',
  CREATE_PREBOOKING_ORDER: 'CREATE_PREBOOKING_ORDER',
  RESET_CREATE_PREBOOKING_ORDER: 'RESET_CREATE_PREBOOKING_ORDER',
  LOADING_PREBOOKING: 'LOADING_PREBOOKING',
  UPDATE_TRANSACTION_RESPONSE: 'UPDATE_TRANSACTION_RESPONSE',
  VALIDATEPREBOOKING: 'VALIDATEPREBOOKING',
  UPDATE_EXECUTIVE_AGENT_LIST : 'UPDATE_EXECUTIVE_AGENT_LIST'
}
