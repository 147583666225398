// import { logoutRequest } from 'actions/auth'
import axios from 'axios'
import { getPartnerToken, getUserToken, getXResponse } from '../utils/common'

/**
 * setup axios instance
 */
const $axios = axios.create({
  baseURL: process.env.API_URL,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    language: 'en',
    timezone: 0,
    platform: 3,
    'x-api-key': '552721c7ca971a196e73',
    'x-device-id': 'website',
    'x-os': '',
    'x-os-version': '',
  },
})

export const APIInterceptors = () => {
  $axios.interceptors.request.use(
    (config: any) => {
      const userToken = getUserToken();
      const partnerToken = getPartnerToken();
      let token = partnerToken || userToken
      if (token) {
        config['headers'] = {
          ...config.headers,
          Authorization: 'Bearer ' + token,
        }
      }
      const xResponse = getXResponse();
      // add the x-response for sms otp request
      if (config.url == 'matter-auth/v1/auth/sms') {
        if (xResponse) {
          config['headers'] = {
            ...config.headers,
            'x-preset-token' : xResponse
          }
        }
      }      
      const getAPIKey = () => {
        const route = config.url.split('/')[0]

        console.log('URL', config.url)

        // change the api key for Geo location
        if (config.url.includes('orbit-geo/v1/location')) {
          return '70c1dadff7fc66389e3c63'
        }
        if (config.url.includes('orbit-config')) {
          return 'eyJhbGciOiJIUzUxMiJ9zKLnPOXWrC5'
        }

        switch (route) {
          case 'orbit-product':
            return '0da0b993dc67298572d0'
          case 'matter-auth':
            return '552721c7ca971a196e73'
          case 'orbit-dealer':
            return 'kCsZAmOtiBDI6k0n'
          case 'orbit-ec':
            return '552721c7ca971a196e73'   
          default:
            return '0da0b993dc67298572d0'
        }
      }
      config['headers'] = {
        ...config.headers,
        'x-api-key': getAPIKey(),
      }
      return config
    },
    (Error) => Promise.reject(Error)
  )
  $axios.interceptors.response.use(
    (response) => response,
    (Error) => Promise.reject(Error)
  )
}

$axios.interceptors.request.use(
  (config: any) => {
    let token = getUserToken()

    // change the api key for Geo location
    if (config.url == 'orbit-geo/v1/location') {
      config['headers'] = {
        ...config.headers,
        ' x-api-key': '70c1dadff7fc66389e3c63',
      }
    }

    if (token) {
      config['headers'] = {
        ...config.headers,
        //extra headers to client
        Authorization: 'Bearer ' + token,
      }
    }

    return config
  },
  (Error) => Promise.reject(Error)
)

$axios.interceptors.response.use(
  (response) => response,
  (Error) => Promise.reject(Error)
)

export const API_SUCCESS_CODE = {
  success: 200,
}

APIInterceptors()



const updateSMSInterceptor = () => {
  $axios.interceptors.request.use((config: any) => {
    if (config.url === 'matter-auth/v1/auth/sms') {
      const storedToken = localStorage.getItem('x-preset-token');
      if (storedToken) {
        config.headers['x-preset-token'] = storedToken;
      }
    }
    return config;
  });
}

updateSMSInterceptor();

// Strapi Headers and config

export const Strapi_Base_URl = process.env.STRAPI_URL

var myHeaders = new Headers()
myHeaders.append(
  'Authorization',
  'Bearer decfeca61dc92a454003cea51ba3efff985eadc7369a07869f7eabd9c3314a5546350ceb38a48a7db60c780035b979a93a2daecd25bd33bf668ad6f6dbe861110e5962644782c8eb839c030cd7f05645157b697eb3e30c8b5c592b9543bac1fdd5af7bf0585d0b4e3023b387511349b3a3fb83c1a7329c3a734410578b6a9a13'
)

export const requestOptions = {
  headers: myHeaders,
  redirect: 'follow',
}

export const fetchMethods = {
  get: (url: string, type: any) => {
    return fetch(url, { ...requestOptions, method: type })
  },
  post: (url: string, data: any) => {
    return fetch(url, data)
  },
  patch: (url: string, data: any) => {
    return fetch(url, data)
  },
  delete: (url: string) => {
    return fetch(url)
  },
  put: (url: string, data: any) => {
    return fetch(url, data)
  },
}

export default {
  axios: $axios,
}
