import React, { useEffect, useState, ReactNode } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { setXResponse } from '../../utils/common';
interface ReCaptchaComponentProps {
  children: ReactNode;
}


const ReCaptchaComponentV3: React.FC<ReCaptchaComponentProps> = ({ children }) => {
  const [isVerifying, setIsVerifying] = useState(false);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    const existingScript = document.querySelector('script[src*="recaptcha"]');
    if (existingScript) {
      existingScript.remove();
    }

    // Reset global reCAPTCHA instance
    if (window.grecaptcha) {
      delete window.grecaptcha;
    }

    // Load the reCAPTCHA script
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_KEY_V3}`;
    script.async = true;
    script.defer = true;

    script.onload = () => {
      setIsScriptLoaded(true);
      // Initialize reCAPTCHA after script loads
      window.grecaptcha?.ready(() => {
        console.log('reCAPTCHA is ready');
      });
    };

    document.head.appendChild(script);

    return () => {
      // Cleanup on unmount
      script.remove();
      if (window.grecaptcha) {
        delete window.grecaptcha;
      }
    };
  }, []); 

  // Add CSS to reposition the reCAPTCHA badge
  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      .grecaptcha-badge {
        position: fixed !important;
        right: 0 !important;
        bottom: 14px !important;
        z-index: 1000 !important;
        transform: translateX(188px); /* Partially hide the badge */
        transition: transform 0.3s ease, opacity 0.3s ease !important;
      }
  
      .grecaptcha-badge:hover {
        transform: translateX(0px) !important; /* Fully show the badge on hover */
      }
  
      .grecaptcha-badge * {
        pointer-events: auto !important; /* Ensure links inside the badge remain clickable */
      }
    `;
    document.head.appendChild(style);
  
    return () => {
      style.remove(); // Cleanup on unmount
    };
  }, []);
  
  
  const handleCaptchaVerification = async () => {
    setIsVerifying(true);
    try {
      // Check if grecaptcha is available
      if (!window.grecaptcha) {
        throw new Error('reCAPTCHA not loaded');
      }

      // Wait for reCAPTCHA to be ready
      await new Promise<void>((resolve) => {
        window.grecaptcha.ready(() => resolve());
      });

      const token = await window.grecaptcha.execute(
        process.env.RECAPTCHA_KEY_V3 || '',
        { action: 'sms_request' }
      );


      localStorage.setItem('x-preset-token', token);
      setXResponse(token);

      return token;
    } catch (err) {
      console.error('ReCAPTCHA verification failed:', err);

      if (!isScriptLoaded) {
        setIsScriptLoaded(false);
        window.location.reload();
      }
      throw err;
    } finally {
      setIsVerifying(false);
    }
  };

  
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        onClick: async (e: React.MouseEvent) => {
          e.preventDefault();
          try {
            await handleCaptchaVerification();


            if (child.props.onClick) {
              await child.props.onClick(e);
            }
   
           
            if (child.props.type === 'submit') {
              const form = (e.target as HTMLElement).closest('form');
              if (form) {
                const submitEvent = new Event('submit', {
                  cancelable: true,
                  bubbles: true,
                });
                form.dispatchEvent(submitEvent);
              }
            }
          } catch (error) {
            console.error('ReCAPTCHA verification failed:', error);
          }
        },
        disabled: isVerifying || !isScriptLoaded || child.props.disabled,
      });
    }
    return child;
  });

  return <>{childrenWithProps}</>;
};


declare global {
  interface Window {
    grecaptcha: {
      ready: (callback: () => void) => void;
      execute: (siteKey: string, options: { action: string }) => Promise<string>;
    };
  }
}

export default ReCaptchaComponentV3;