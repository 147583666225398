const Images = {
  Aerablue:'/public/assets/images/bikes/aera-blue.png',
  Logo: '/public/assets/images/matter-logo.svg',
  OnboardingBanner: 'https://sitecdn.matter.in/newsite-uat/matter/assets/images/NEW_IMAGES/Login_Desktop.png',
  OnboardingBanner_Mobile:'https://sitecdn.matter.in/newsite-uat/matter/assets/images/NEW_IMAGES/Login_Mobile.png',
  ChangeLoginNoBanner: '/public/assets/images/change-login-no-banner.webp',
  Aera5000Plus: '/public/assets/images/5000+SVG.svg',
  Aera5000: '/public/assets/images/5000SVG.svg',
  Aera5000Big:'/public/assets/images/AERA5000.svg',
  Aera5000PlusBig:'/public/assets/images/AERA5000Plus.svg',
  Hero: 'https://sitecdn.matter.in/newsite-uat/matter/assets/images/hero-img.webp',
  Motor:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/motor.webp',
  Spots:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike-spots.webp',
  Bike: 'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike.webp',
  BikeRide:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike-ride.mp4',
  BlackTile: '/public/assets/images/black-color.svg',
  YellowTile: '/public/assets/images/yellow-color.svg',
  GreyTile: '/public/assets/images/grey-color.svg',
  RedTile: '/public/assets/images/red-color.svg',
  BlueTile: '/public/assets/images/blue-color.svg',
  BlackBike:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/Variants/Black.webp',
  BlackBikeChangedAssestNew:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/Variants/Changed_Assests/Aera5000Plus/Black_5000+_new.webp',
  BlackBike1:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/Variants/Black1.webp',
  BlackBikeChangedAssest: 
    'https://sitecdn.matter.in/newsite-uat/matter/assets/Variants/Changed_Assests/Aera5000/Black_5000_new.webp',
  YellowBike:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/Variants/Grey.webp',
  GrayBikeChangedAssestNew:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/Variants/Changed_Assests/Aera5000Plus/Gray_5000+_new.webp',
  GreyBike:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/Variants/White_new.webp',
  WhiteBikeChangedAssestNew:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/Variants/Changed_Assests/Aera5000Plus/White_5000+_new_img.webp',
  RedBike:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/Variants/Red_new.webp',
  RedBikeChangedAssestNew:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/Variants/Changed_Assests/Aera5000Plus/Red_5000+_new_img.webp',
  BlueBike:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/Variants/Blue.webp',
  BlueBikeChangedAssestNew:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/Variants/Changed_Assests/Aera5000Plus/Blue_5000+_new_img.webp',
  BlueBike1:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/Variants/Blue1.webp',
  BlueBikeChangedAssest:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/Variants/Changed_Assests/Aera5000/Blue_5000_new_img.webp',

  HomeVideo:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/homeVideo.webm',
  HomeVideoMp4:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/Homevideo.mp4',

  BlackBikeThumb:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/Variants/thumbnail/Black.webp',
  BlackBike1Thumb:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/Variants/thumbnail/Black1.webp',
  YellowBikeThumb:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/Variants/thumbnail/Grey.webp',
  GreyBikeThumb:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/Variants/thumbnail/White.webp',
  RedBikeThumb:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/Variants/thumbnail/Red.webp',
  BlueBikeThumb:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/Variants/thumbnail/Blue.webp',
  BlueBike1Thumb:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/Variants/thumbnail/Blue1.webp',

  AeraBike: '/public/assets/images/aera-bike.svg',
  Hotspot:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/hotspot.webp',
  HotspotNew:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/hotspot-new-img.webp',
  VUI: 'https://sitecdn.matter.in/newsite-uat/matter/assets/images/vui.webp',
  VUI1: 'https://sitecdn.matter.in/newsite-uat/matter/assets/vui/vui1.webp',
  VUI2: 'https://sitecdn.matter.in/newsite-uat/matter/assets/vui/vui2.webp',
  VUI3: 'https://sitecdn.matter.in/newsite-uat/matter/assets/vui/vui3.webp',
  VUI4: 'https://sitecdn.matter.in/newsite-uat/matter/assets/vui/vui4.webp',
  App: 'https://sitecdn.matter.in/newsite-uat/matter/assets/images/app.webp',
  Apps: 'https://sitecdn.matter.in/newsite-uat/matter/assets/images/apps.webp',
  Charger:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/charger.webp',
  ChargerMobile:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/charger-mobile.webp',
  SmartPark:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/smart-parking.webp',
  SmartParkMobile:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/smart-parking-mobile.webp',
  Graphics:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/graphics.webp',
  GraphicsNew:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/graphics_new.webp',
  GraphicsMobile:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/graphics-mobile.webp',
  Dpad: 'https://sitecdn.matter.in/newsite-uat/matter/assets/images/dpad.webp',
  DpadNew: 'https://sitecdn.matter.in/newsite-uat/matter/assets/images/dpad-new.webp',
  DpadMobile:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/dpad-mobile.webp',
  Storage1:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/storage1.webp',
  Storage1New:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/storage1_new.webp',
  Storage1Mobile:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/storage1-mobile.webp',
  Alloys:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/alloys.webp',
  AlloysNew:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/alloys-new.webp',
  AlloysMobile:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/alloys-mobile.webp',
  KeyImg:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/keyImg.webp',
  KeyImgNew:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/keyImgNew.webp',
  KeyImgMobile:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/keyImg-mobile.webp',
  Passive:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/passive.webp',
  PassiveMobile:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/passive-mobile.webp',
  Star: 'https://sitecdn.matter.in/newsite-uat/matter/assets/images/star.webp',
  StarNew: 'https://sitecdn.matter.in/newsite-uat/matter/assets/images/star-new.webp',
  StarMobile:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/star-mobile.webp',
  Triangle:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/triangle.webp',
  TriangleNew:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/triangle-new.webp',
  TriangleMobile:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/triangle-mobile.webp',
  Grabrail:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/grabrail.webp',
  GrabrailNew:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/grrabrail_new.webp',
  GrabrailMobile:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/grabrail-mobile.webp',
  Handle:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/handle.webp',
  Abs: 'https://sitecdn.matter.in/newsite-uat/matter/assets/images/abs.webp',
  Key: 'https://sitecdn.matter.in/newsite-uat/matter/assets/images/key.webp',
  Storage:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/storage.webp',
  PKE: 'https://sitecdn.matter.in/newsite-uat/matter/assets/images/pke.webp',
  Skid: 'https://sitecdn.matter.in/newsite-uat/matter/assets/images/skid.webp',
  Elements: '/public/assets/images/elements.svg',
  Aera: 'https://sitecdn.matter.in/newsite-uat/matter/assets/images/aera.webp',
  Model: '/public/assets/images/model.svg',
  Explore:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/explore.webp',
  HotspotBike:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/hotspot-bike.webp',
  HotspotBikeNew:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/hotspot-bike-new.webp',  

  BlackClr: '/public/assets/images/black-clr.svg',
  BlueClr: '/public/assets/images/blue-clr.svg',
  YellowClr: '/public/assets/images/yellow-clr.svg',
  GreyClr: '/public/assets/images/grey-clr.svg',
  RedClr: '/public/assets/images/red-clr.svg',
  VUIvideo:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/vui-video.webm',
  SplashKeyM3U8: 'public/assets/images/splashVideoOut.m3u8',
  SplashMobileM3U8:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/splashVideoMobile.webm',
  HomeVideoM3U8:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/Aera_FutureOfRiding.m3u8',
  SplashVideo:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/splash-video.webm',
  SplashVideoMp4:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/splashVideo.mp4',
  SplashVideoMobile:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/splash-videoMobile.webm',
  SplashVideoMobileMp4:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/splashVideoMobile.mp4',

  AccountsComingSoon: '/public/assets/images/accounts/coming-soon.svg',
  AeraWithRider: '/public/assets/images/accounts/aera-with-rider.svg',
  DummyUser: '/public/assets/images/accounts/dummy-user.svg',
  DummyUserLight: '/public/assets/images/accounts/dummy-user-light.svg',
  AddProfilePlaceholder:
    '/public/assets/images/accounts/add-profile-placeholder.svg',
  dummy: '/public/assets/images/accounts/dummy.webp',

  ProductImgM2e: '/public/assets/images/M2E.svg',
  ProductImg2: '/public/assets/images/product2.svg',
  ProductImg3: '/public/assets/images/product3.svg',
  // ProductImg3: 'https://sitecdn.matter.in/newsite-uat/matter/assets/images/product3.svg',
  ProductImg3_tn: '/public/assets/images/product3.svg',

  // https://sitecdn.matter.in/newsite-uat/matter/assets/matterCare/

  MobileApp: '/public/assets/images/mobileapp.svg',
  BikePreview: '/public/assets/images/bikepreview.svg',
  LightFront: '/public/assets/images/Lights_FrontIndicator.svg',
  HomeDock: '/public/assets/images/homedock.svg',
  CreateAeraBg: '/public/assets/images/accounts/create-aera-bg.svg',

  HeadLamp: '/public/assets/hotspot/Headlamp_Fts-min.webp',
  HeadLampNew: 'https://sitecdn.matter.in/newsite-uat/matter/assets/hotspot/Headlamp_Fts_New.webp',
  HeadLampMob:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/Headlamp_Fts-mob.webp',

  Charger2: '/public/assets/hotspot/ChargerOnBoard-min.webp',
  Charger2New: 'https://sitecdn.matter.in/newsite-uat/matter/assets/hotspot/ChargerOnBoard_New.webp',
  ChargerMob:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/ChargerOnBoard-mob.webp',

  TailLamp: '/public/assets/hotspot/Tailamp_Rts-min.webp',
  TailLampNew: 'https://sitecdn.matter.in/newsite-uat/matter/assets/hotspot/Tailamp_Rts_New.webp',
  TailLampMob:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/Tailamp_Rts-mob.webp',

  Display: '/public/assets/hotspot/ViuComp-min.webp',
  DisplayNew: 'https://sitecdn.matter.in/newsite-uat/matter/assets/hotspot/VIUCompNew.webp',
  DisplayMob:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/Viu-mob.webp',

  ABS: '/public/assets/hotspot/ABS-min.webp',
  ABS_New: 'https://sitecdn.matter.in/newsite-uat/matter/assets/hotspot/ABS_New.webp',
  ABSMob:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/ABS-mob.webp',

  Charging:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/charging.webp',
  Charging_New:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/charging_black.png',
  FindMatter:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/find_my_matter.webp',
  FindMatterNew:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/find_my_matter_new.png',
  RideStats:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/ride_stats.webp',
  RideStatsNew:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/ride_stats_new.png',
  Locked:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/locked.webp',
  Unlocked:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/unlocked.webp',
  UnlockedNew:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/unlocked_new.png',
  PlayBtn: '/public/assets/images/home/play-btn.svg',
  ArrowWhite: '/public/assets/images/home/arrow-white.svg',

  View360_black5000:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike-360-view-new/5000_Black_360.webm',
  View360_black5000_plus:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike-360-view-new/5000+_Black_360.webm',

  View360_blue5000:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike-360-view-new/5000_Blue_360.webm',
  View360_blue5000_plus:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike-360-view-new/5000+_Blue_360.webm',

  View360_red5000:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike-360-view/Red5000.webm',
  View360_red5000_plus:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike-360-view-new/5000+_Red_360.webm',

  View360_white5000:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike-360-view/White5000.webm',
  View360_white5000_plus:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike-360-view-new/5000+_White.webm',

  View360_gray5000:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike-360-view/Grey5000.webm',
  View360_gray5000_plus:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike-360-view-new/5000+_Gray_360.webm',

  View360_blackMP45000:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike-360-view/5000Black360.mov',
  View360_blackMP45000_plus:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike-360-view/5000+Black360.mov',

  View360_blueMP45000:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike-360-view/5000Blue360.mov',
  View360_blueMP45000_plus:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike-360-view/5000+Blue360.mov',

  View360_redMP45000:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike-360-view/Red5000.mp4',
  View360_redMP45000_plus:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike-360-view/5000+Red360.mov',

  View360_whiteMP45000:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike-360-view/White5000.mp4',
  View360_whiteMP45000_plus:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike-360-view/5000+White360.mov',

  View360_grayMP45000:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike-360-view/Grey5000.mp4',
  View360_grayMP45000_plus:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/bike-360-view/5000+Gray360.mov',

  Hometestimonial:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home-testimonial.webp',

  PauseBtn: '/public/assets/images/home/pause-iocn.svg',
  PlayVideo: '/public/assets/images/home/play-video.svg',
  Closevideo: '/public/assets/images/home/close-video.svg',

  BikeInspection:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/tribeinspecton.webp',
  BikeInspectionThumbnail:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/homeThumbnail/tribeinspecton.webp',
  BikeInspectionMobile:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/biketestMobile.webp',
  BikeInspectionMobileThumbnail:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/homeThumbnail/biketestMobile.webp',
  BikeModel:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/mattertribe.webp',
  BikeModelThumbail:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/homeThumbnail/mattertribe.webp',
  BikeModelMobile:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/mattertribeMobile.webp',
  BikeModelThumbailMobile:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/homeThumbnail/mattertribeMobile.webp',
  BikeTest:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/biketest.webp',
  BikeTestThumbail:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/homeThumbnail/biketest.webp',
  BikeTestMobile:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/tribeinspectonMobile.webp',
  BikeTestMobileThumbail:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/homeThumbnail/tribeinspectonMobile.webp',

  SupportBanner: 'https://sitecdn.matter.in/newsite-uat/matter/assets/images/NEW_IMAGES/Support_Desktop.png',
  SupportBannerMobile: 'https://sitecdn.matter.in/newsite-uat/matter/assets/images/NEW_IMAGES/Support_Mobile.png',
  FaqBanner: '/public/assets/images/faq-banner.svg',
  FaqBannerMobile: '/public/assets/images/faq-banner-mobile.svg',
  PartnerWithUsBanner:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/NEW_IMAGES/Partner_With_Us_Desktop1.png',
  PartnerWithUsBannerMobile:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/NEW_IMAGES/Partner_With_Us_Mobile1.png',

  Play: '/public/assets/images/home/play.svg',
  pause: '/public/assets/images/home/pause.svg',
  pause_button: '/public/assets/images/home/pause_button.svg',
  play_button: '/public/assets/images/home/play_button.svg',
  Homebannervideo: '/public/assets/images/home/Ffutureofriding.webm',
  Closetestimonial: '/public/assets/images/home/close_testimonial.svg',

  RYI_Banner:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/NEW_IMAGES/Form_top_Banner_Bg_Desktop.png',
  RYI_BannerThumbnail:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/NEW_IMAGES/Form_top_Banner_Bg_Desktop.png',   
  RYI_Banner_Mobile:
  'https://sitecdn.matter.in/newsite-uat/matter/assets/images/NEW_IMAGES/Form_top_Banner_Bg_Mobile.png',
 RYI_BannerThumbnail_Mobile:
  'https://sitecdn.matter.in/newsite-uat/matter/assets/images/NEW_IMAGES/Form_top_Banner_Bg_Mobile.png',

  Conceptut:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/concept-exe.webp',
  ConceptutThumbnail:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/homeThumbnail/concept-exe.webp',

  Conceptexe:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/concept-ut.webp',
  ConceptexeThumbnail:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/homeThumbnail/concept-ut.webp',

  Inverter:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/inverter.webp',
  InverterThumbnail:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/homeThumbnail/inverter.webp',

  keyfobWeb:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/keyfobWeb.webp',
  keyfobMobile:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/keyfobMobile.jpg',
  AppLoader: 'https://sitecdn.matter.in/newsite-uat/matter/assets/loader.json',
  homeVideoPoster:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/homeVideoPoster.webp',
  Hometestimonial_mobile:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/Floating-Image-Mobile.webp',
  Hometestimonial_web:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/Floating-Image-Web.webp',
  homeHeaderPopupWeb:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/Popup-Image-Web.webp',
  homeHeaderPopupMobile:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/Popup-Image-Mobile.webp',
  cancelReasonImg:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/NEW_IMAGES/CancelConfirmationPopup_Desktop.png',
  RequestAssistanceIcon:
    '/public/assets/images/accounts/request-assistance-icon.svg',
  RequestAssistanceBikeImage: '/public/assets/images/accounts/bike-image.webp',
  SlanterBY: '/public/assets/images/slanter_by.png',
  SlanterYB: '/public/assets/images/slanter_yb.png',
  varient360ring:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/Variants/varient360ring.webp',

  MissionVision: '/public/assets/images/mission-vision.svg',
  MissionVisionMobile: '/public/assets/images/mission-vision-mobile.svg',
  firstImpact: '/public/assets/images/first-impact.svg',
  firstImpactMobile: '/public/assets/images/first-impact-mobile.svg',
  secondImpact: '/public/assets/images/second-impact.svg',
  secondImpactMobile: '/public/assets/images/second-impact-mobile.svg',
  IesaReward: '/public/assets/images/iesa-reward.svg',
  SymphonyReward: '/public/assets/images/symphony-reward.svg',
  innovationHub:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/innovation_hub_video.webm',
  innovationHubmp4:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/innovation_hub_video.mp4',
  innovationHub_Poster:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/innovation_poster.webp',

  whoWeAre_clip:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/whoWeAre_video_Clip.webm',
  whoWeAre_clip_mp4:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/whoWeAre_video_Clip.mp4',
  whoWeAre_clipPoster:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/home/whoWeAre_clipPoster.webp',

  homePage_drone:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/homePageDrone.webm',
  homePage_drone_mp4:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/homePageDrone.mp4',

  homePage_fullDrone:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/homePageFullDron.webm',
  homePage_fullDrone_mp4:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/homePageFullDron.mp4',

  homePage_video_poster:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/homePageVideoPoster.png',

  MohalLalBhai: '/public/assets/images/leaders/mohal-lalbhai.svg',
  MohalLalBhaiMobile: '/public/assets/images/leaders/mohal-lalbhai-mobile.svg',
  ArunPratapSingh: '/public/assets/images/leaders/arun-pratap-singh.svg',
  ArunPratapSinghMobile:
    '/public/assets/images/leaders/arun-pratap-singh-mobile.svg',
  SaranBabu: '/public/assets/images/leaders/saran-babu.svg',
  SaranBabuMobile: '/public/assets/images/leaders/saran-babu-mobile.svg',
  PrasadTelikapalli: '/public/assets/images/leaders/prasad-telikapalli.svg',
  PrasadTelikapalliMobile:
    '/public/assets/images/leaders/prasad-telikapalli-mobile.svg',

  TheBeginning: '/public/assets/images/our-story/the-beginning.svg',
  Growth: '/public/assets/images/our-story/growth.svg',
  BirthOfAera: '/public/assets/images/our-story/birth-of-aera.svg',
  ProductExhibition: '/public/assets/images/our-story/product-exhibition.svg',

  SpinnerLoader: '/public/assets/spinner-loader.svg',
  GoogleMapMarker: '/public/assets/icons/google-map-marker.svg',
  hammerBorder: '/public/assets/images/hammer-border.svg',

  MyVisits: '/public/assets/images/my-visits.png',
  MyAccountMyVisit: '/public/assets/images/my-visit.svg',
  MyAccountMyVisit2: '/public/assets/images/my-visits2.png',
  entryPopup:'/public/assets/images/entry-popup.png',
  // hammerBorder:'/public/assets/images/hammer-border.svg',

  MatterStreamHeader:'https://sitecdn.matter.in/newsite-uat/matter/assets/images/NEW_IMAGES/Stream_Desktop_New.webp',
  MatterStreamHeader_Mobile:'https://sitecdn.matter.in/newsite-uat/matter/assets/images/NEW_IMAGES/Stream_Mobile_New.webp',
  AhamadabadCityLocation:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/ahmedabad-city-img.webp',
  AhamadabadCityLocationMobImg:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/Ambekar-Bridge-mob.webp',
  EMIBackImgMobile:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/NEW_IMAGES/HyperLocal_EMI_Cal_Mobile.png',
  BackDropImage:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/matter-space-bg-desk.webp',
  rectangleShape:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/rectangle.webp',
  EmiBgFrame:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/NEW_IMAGES/HyperLocal_EMICal_Banner_Desktop.png',
  HyperBannerBg:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/hyper-banner-bg.webp',
  MatterSpaceFort:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/jhulta-minar.webp',
  MatterSpaceGate:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/gateway-of-india.webp',
  BackdropRender:
    'https://sitecdn.matter.in/newsite-uat/matter/assets/images/matter-space-bg-mob.webp',
    Aera5000PlusApp: '/public/assets/images/bikes/AERA-5000-plus-black.png',
    Aera5000App: '/public/assets/images/bikes/AERA-5000-White.png',
    
}

export default Images
